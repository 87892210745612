import { v1 as uuid } from 'uuid';
import Web2App from '@wowmaking/web2app';

const storageKey = 'EXTERNAL_ID';
let external_id = window.localStorage.getItem(storageKey);
if (!external_id) {
  external_id = uuid();
  window.localStorage.setItem(storageKey, external_id);
}

window.fbq('init', process.env.REACT_APP_FB_PIXEL_KEY, { external_id, });
window.fbq('track', "PageView");

Web2App.init({ systems: ['facebook'], })
  .then(() => {
    const link = process.env.REACT_APP_ADJUST_LINK && process.env.REACT_APP_ADJUST_TOKEN
      ? Web2App.createAdjustLink(process.env.REACT_APP_ADJUST_LINK, process.env.REACT_APP_ADJUST_TOKEN, { web_external_id: external_id, })
      : Web2App.createAppsFlyerLink(process.env.REACT_APP_ONELINK, { web_external_id: external_id, });;
    window.location.href = link;
  });
